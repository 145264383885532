var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[_c('v-btn',{staticClass:"mb-0 mr-4 px-4",attrs:{"color":"primary"},on:{"click":_vm.goToNeedTrainingForm}},[_vm._v(" "+_vm._s(_vm.$t('btn.add_trainee'))+" ")]),_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"color":"primary","to":_vm.getLastRouteLogisticSession}},[_vm._v(" "+_vm._s(_vm.$t('btn.return'))+" ")])],1)],2),_c('v-container',{attrs:{"id":"need_training_associate_training_session_list-list","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 pt-3 mb-15",attrs:{"dark":_vm.$vuetify.theme.dark,"inline":"","color":"primary","icon":"mdi-card-account-details"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"pt-0 pb-2"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t( 'need_training_associate_training_session_list.list.title' ))+" ")])])]},proxy:true}])},[_c('v-row',[_c('v-col',{attrs:{"md":"9"}}),_c('v-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-btn',{staticClass:"mb-0 px-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.associateCheckedNeedTrainingsToTrainingSession()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.associate_trainees'))+" ")])],1)])],1),_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"headers":_vm.headers,"items":_vm.associateNeedTrainingSessionList,"loading":_vm.loadingNeedTrainingList,"options":_vm.paginationOptions,"hide-default-footer":""},on:{"update:sort-by":_vm.onSortBy,"update:sort-desc":_vm.onSortDesc},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center ma-2 rounded"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkedNeedTrainings),expression:"checkedNeedTrainings"}],attrs:{"type":"checkbox"},domProps:{"value":item['@id'],"checked":Array.isArray(_vm.checkedNeedTrainings)?_vm._i(_vm.checkedNeedTrainings,item['@id'])>-1:(_vm.checkedNeedTrainings)},on:{"change":function($event){var $$a=_vm.checkedNeedTrainings,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item['@id'],$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedNeedTrainings=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedNeedTrainings=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedNeedTrainings=$$c}}}})])]}},{key:"header.trainee.lastName",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t(
                      'need_training_associate_training_session_list.list.filter.lastName'
                    ),"value":_vm.filters.lastNameSearched},on:{"filter-list":_vm.filterByLastName}})],1)]}},{key:"header.trainee.firstName",fn:function(ref){
                    var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t(
                      'need_training_associate_training_session_list.list.filter.firstName'
                    ),"value":_vm.filters.firstNameSearched},on:{"filter-list":_vm.filterByfirstName}})],1)]}},{key:"header.businessIntroducer.name",fn:function(ref){
                    var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t(
                      'need_training_associate_training_session_list.list.filter.businessIntroducer'
                    ),"value":_vm.filters.businessIntroducerSearched},on:{"filter-list":_vm.filterByBusinessIntroducer}})],1)]}},{key:"header.company.name",fn:function(ref){
                    var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListSelectFilter',{attrs:{"translation":_vm.$t(
                      'need_training_associate_training_session_list.list.filter.company'
                    ),"value":_vm.filters.companySearched,"items":_vm.listCompanies,"item-text":function (item) { return item.name; }},on:{"filter-list":_vm.filterByCompany}})],1)]}},{key:"header.branch.name",fn:function(ref){
                    var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t(
                      'need_training_associate_training_session_list.list.filter.branch'
                    ),"value":_vm.filters.branchSearched},on:{"filter-list":_vm.filterByBranch}})],1)]}},{key:"header.trainee.area.name",fn:function(ref){
                    var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListSelectFilter',{attrs:{"translation":_vm.$t(
                      'need_training_associate_training_session_list.list.filter.area'
                    ),"value":_vm.filters.areaSearched,"items":_vm.listAreas,"item-text":function (item) { return _vm.$t('form.area', {
                    name: item.name,
                  }); }},on:{"filter-list":_vm.filterByArea}})],1)]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination-options":_vm.paginationOptions,"nb-pages":_vm.nbPages},on:{"changePaginationOptions":function($event){_vm.paginationOptions = $event}}})]},proxy:true}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }