import { ref, computed, Ref } from '@vue/composition-api'
import { DataOptions } from 'vuetify'

export interface PaginationOptions {
  paginationOptions: DataOptions
  paginationRequestParams: Record<string, unknown>
  nbPages: number
  onSortBy: () => void,
  onSortDesc: () => void,
}

export function parseQuery (query: any): DataOptions {
  const stringQuery = Object.values(query).some(value => typeof value === 'string')
  return stringQuery ? {
    page: query.page ? parseInt(query.page) : 1,
    itemsPerPage: query.itemsPerPage ? parseInt(query.itemsPerPage) : 15,
    sortBy: query.sortBy && !Array.isArray(query.sortBy) ? query.sortBy.split(',') : [],
    sortDesc: query.sortDesc && !Array.isArray(query.sortDesc) ? query.sortDesc.split(',').map((x: string) => x === 'true') : [],
    groupBy: query.groupBy && !Array.isArray(query.groupBy) ? query.groupBy.split(',') : [],
    groupDesc: query.groupDesc && !Array.isArray(query.groupDesc) ? query.groupDesc.split(',').map((x: string) => x === 'true') : [],
    multiSort: query.multiSort === 'true',
    mustSort: query.mustSort === 'true',
  } : query || {}
}
export function usePagination (totalItems: () => number, query?: DataOptions): unknown {
  const paginationOptions: Ref<DataOptions> = ref({
    page: query?.page ? query.page : 1,
    itemsPerPage: query?.itemsPerPage ? query.itemsPerPage : 15,
    sortBy: query?.sortBy && !Array.isArray(query.sortBy) ? query.sortBy : [],
    sortDesc: query?.sortDesc && !Array.isArray(query.sortDesc) ? query.sortDesc : [false],
    groupBy: query?.groupBy && !Array.isArray(query.groupBy) ? query.groupBy : [],
    groupDesc: query?.groupDesc && !Array.isArray(query.groupDesc) ? query.groupDesc : [],
    multiSort: query?.multiSort ? query.multiSort : false,
    mustSort: query?.mustSort ? query.mustSort : false,
  })
  const orderProperty = computed((): string | null => {
    // create dynamic order parameter with the the value asc/des ex : order['name'] = asc
    if (paginationOptions.value.sortBy && paginationOptions.value.sortBy.length > 0) {
      return `order[${paginationOptions.value.sortBy[0]}]`
    }
    return null
  })

  const sortOrder = computed((): string | null => {
    if (paginationOptions.value.sortDesc.length > 0) {
      return paginationOptions.value.sortDesc[0] ? 'desc' : 'asc'
    }
    return null
  })

  const nbPages = computed((): number => {
    if (totalItems()) {
      return Math.ceil(
        totalItems() / (paginationOptions.value.itemsPerPage),
      )
    }
    return 1
  })
  const paginationRequestParams = computed((): Record<string, unknown> => {
    return {
      page: paginationOptions.value.page,
      itemsPerPage: paginationOptions.value.itemsPerPage,
      [orderProperty.value as string]: sortOrder.value,
    }
  })

  const onSortBy = (eventSortBy: string[]) => {
    if (eventSortBy.length === 0) {
      onSortDesc([false])
    }
    paginationOptions.value.sortBy = eventSortBy
  }

  const onSortDesc = (eventSortDesc: boolean[]) => {
    paginationOptions.value.sortDesc = eventSortDesc
  }
  return {
    paginationOptions,
    paginationRequestParams,
    nbPages,
    onSortBy,
    onSortDesc,
  }
}
