



































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TopBar',
  props: {
    title: {
      required: true,
      type: String,
    },
    pages: {
      required: false,
      type: Array,
      default: () => {
        return null
      },
    },
  },
  data () {
    return {
      currentTab: '',
    }
  },
})
